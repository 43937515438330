
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { listByCourseSeriesId } from '@/api/entity/course'
import { expandedCourseSeries, allCourseSeries } from './state'
import { auditedHomeworkList, getPendingHomewrokGroupByCourseId } from '@/api/user/teacher'
import { HomeworkGroupByCourseId } from '@/api/user/teacher/types'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<'approved' | 'pending'>,
      default: 'approved'
    },
    emptyText: {
      type: String,
      default: '该课程下暂无已批作业'
    }
  },

  setup: (props) => {
    const isApproved = computed(() => props.type === 'approved')
    const homeworkList = ref<HomeworkGroupByCourseId>({})
    const getHomeworkListByCourseId = computed(() => (id: number) => homeworkList.value[id] || [])
    const courseSeriesWithHomeworks = computed(() => {
      const res = allCourseSeries.value.filter(
        c => c.courses.some(
          c => getHomeworkListByCourseId.value(Number(c.id)).length > 0))
      res.forEach(cs => {
        cs.courses = cs.courses.filter(c => getHomeworkListByCourseId.value(Number(c.id)).length > 0)
      })
      return res
    })
    const setCourseSeriesDot = () => {
      allCourseSeries.value.forEach(c => {
        listByCourseSeriesId(Number(c.id)).then(r => {
          c.courses = r.map(({ id, ...others }) => ({
            id,
            ...others,
            hasDot: !!homeworkList.value[Number(id)]
          }))
          c.hasDot = c.courses.some(c => homeworkList.value[Number(c.id)])
        })
      })
    }

    const fetch = () => {
      if (props.type === 'approved') {
        auditedHomeworkList().then(r => {
          homeworkList.value = r
          setCourseSeriesDot()
        })
        return
      }
      getPendingHomewrokGroupByCourseId().then(r => {
        homeworkList.value = r
        setCourseSeriesDot()
      })
    }
    fetch()

    watch(allCourseSeries, setCourseSeriesDot)

    return {
      isApproved,
      getHomeworkListByCourseId,
      expandedCourseSeries,
      courseSeriesWithHomeworks,
      homeworkList,
      courseSeriesExpandChange: (v: number) => {
        const target = allCourseSeries.value.find(cs => cs.id === v)
        if (target && target.courses.length === 0) {
          listByCourseSeriesId(v).then(r => {
            target.courses = r.map(({ id, ...others }) => ({
              id,
              ...others,
              hasDot: !!homeworkList.value[Number(id)]
            }))
            target.hasDot = target.courses.some(c => homeworkList.value[Number(c.id)])
          })
        }
      }
    }
  }
})
