import {createModuleApi, Homework, MmTeacher} from 'momai'
import { HomeworkGroupByCourseId } from './types'

const teacherApi = <T>() => createModuleApi<T>('teacher')

const mmTeacherApi = <T>() => createModuleApi<T>('mmteacher')

const audit = (h: {
  id: number;
  unread: number;
  teaPic: string;
  teaEva: string;
  status: string;
  teaVoice: string;
  userId: number;
  rsrv1: string;
}) => teacherApi<string>()({
  subUrl: 'audit',
  method: 'post',
  params: h
})

/**
 * 获取某个作业的往期导师点评
 * @param homeworkId 作业ID
 */
const historyAuditInfoForHomework = (homeworkId: number) => teacherApi<Homework[]>()({
  subUrl: `history-audit-info-for-homework/${homeworkId}`
})

/**
 * 获取未批改作业数量
 */
const getPendingHomeworkNum = () => teacherApi<number>()({
  subUrl: 'get-pending-homework-num'
})

/**
 * 获取所有待批作业按照课程ID分组的结果
 */
const getPendingHomewrokGroupByCourseId = () => teacherApi<HomeworkGroupByCourseId>()({
  subUrl: 'get-pending-homewrok-group-by-courseId'
})

/**
 * 获取所有的已批作业按照课程ID分组结果
 */
const auditedHomeworkList = () => teacherApi<HomeworkGroupByCourseId>()({
  subUrl: 'audited-homework-list-group-by-course-id'
})

const getTeacherList = (params: any) => mmTeacherApi<MmTeacher[]>()({
  subUrl: 'page',
  method: 'post',
  params: params
})

const getcourseById = (teacherId: number) => mmTeacherApi()({
  subUrl: `getTeacherCourse/${teacherId}`,
  method: 'post'
})

const getTeacherUserWork = (params: any) => mmTeacherApi()({
  subUrl: 'getTeacherUserWork',
  method: 'post',
  params: params
})

export {
  auditedHomeworkList,
  historyAuditInfoForHomework,
  getPendingHomewrokGroupByCourseId,
  getPendingHomeworkNum,
  audit,
  getTeacherList,
  getcourseById,
  getTeacherUserWork
}
