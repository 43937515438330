import { courseSeriesList } from '@/states/couseSeries'
import { Course, CourseSeries } from 'momai'
import { ref, watch } from 'vue'

interface CourseWithDot extends Course {
  hasDot: boolean;
}

interface CourseSeriesExpand extends CourseSeries {
  expandCourses: number[];
  courses: CourseWithDot[];
  hasDot: boolean;
}

const expandedCourseSeries = ref([])
const allCourseSeries = ref<CourseSeriesExpand[]>([])

const setAll = () => {
  allCourseSeries.value = courseSeriesList.value.map(c => ({
    ...c,
    expandCourses: [],
    courses: [],
    hasDot: false
  }))
}
setAll()
watch(courseSeriesList, setAll)

export {
  allCourseSeries,
  expandedCourseSeries
}
